<template>
	<div class="page">
		<Tables ref="xTable" :tableName="tableName" :tableData="tableData" :totalPage="totalPage" :isLoading="isLoading"
			:searchFormData="searchFormData" :showRefresh="true" :showSearch="true"
			:showAdd="$hasAccess('market.activity/add')" addName="新增活动" @getList="getList" @clearSearch="clearSearch"
			@addItemInTable="addOne">

			<!--搜索自定义内容-->
			<el-form-item slot="search-item">
				<el-input v-model="searchFormData.activity_name" size="small" clearable placeholder="按活动名称查询">
				</el-input>
			</el-form-item>
			<!--表格内容-->
			<vxe-table-column slot="table-item" field="activity_name" title="活动名称" align="center" min-width="100px" />
			<vxe-table-column slot="table-item" field="coupon_id" title="优惠券ID" align="center" min-width="50px" />
			<vxe-table-column slot="table-item" field="coupon_name" title="优惠券名称" align="center" min-width="50px" />
			<vxe-table-column slot="table-item" field="type" title="类型" align="center">
				<template v-slot="{ row }">
					<span>{{types.type[row.type]||'未知类型'}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="start_time" title="开始时间" align="center" min-width="80px" />
			<vxe-table-column slot="table-item" field="end_time" title="结束时间" align="center" min-width="80px" />
			<vxe-table-column slot="table-item" title="操作" align="center" width="400px">
				<template v-slot="{ row }">
					<el-button size="small" plain @click="editOne(row)" v-if="$hasAccess('market.activity/edit')">编辑
					</el-button>
					<el-button size="small" plain @click="deleteOne(row)">删除</el-button>
				</template>
			</vxe-table-column>
		</Tables>

		<!-- 新增 -->
		<el-dialog title="新增活动" :visible.sync="addDialogShow" :close-on-click-modal="false"
			:close-on-press-escape="false" width="500px">
			<el-form :model="addDialogFormData" :rules="rules" ref="addForm" label-width="100px" class="form">
				<el-form-item label="活动名称" prop="activity_name">
					<el-input v-model="addDialogFormData.activity_name" style="width: 80%;"></el-input>
				</el-form-item>
				<el-form-item label="类型" prop="coupon_type">
					<el-radio-group v-model="addDialogFormData.type">
						<el-radio :label="10">正常</el-radio>
						<el-radio :label="20">新开站点</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="关联优惠券" prop="coupon_id" v-if="addDialogFormData.type==10">
					<el-select v-model="addDialogFormData.coupon_id" placeholder="请选择" style="width:100%">
						<el-option v-for="item in couponList" :key="item.coupon_id" :label="item.coupon_name"
							:value="item.coupon_id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="开始时间" prop="select_time">
					<el-date-picker class="from-item-width" unlink-panels v-model="addDialogFormData.select_time"
						type="datetime" placeholder="开启时间"></el-date-picker>
				</el-form-item>
				<el-form-item label="结束时间" prop="select_time2">
					<el-date-picker class="from-item-width" unlink-panels v-model="addDialogFormData.select_time2"
						type="datetime" placeholder="结束时间"></el-date-picker>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" size="small" plain icon="el-icon-check" @click="saveAdd()"
					v-if="$hasAccess('market.activity/add')">保 存
				</el-button>
				<el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消
				</el-button>
			</div>
		</el-dialog>

		<!-- 编辑 -->
		<el-dialog title="编辑活动" :visible.sync="editDialogShow" :close-on-click-modal="false"
			:close-on-press-escape="false" width="500px">
			<el-form :model="editDialogFormData" :rules="rules" ref="editForm" label-width="100px" class="form">
				<el-form-item label="活动名称" prop="activity_name">
					<el-input v-model="editDialogFormData.activity_name" style="width: 80%;"></el-input>
				</el-form-item>
				<el-form-item label="类型" prop="coupon_type">
					<el-radio-group v-model="editDialogFormData.type">
						<el-radio :label="10">正常</el-radio>
						<el-radio :label="20">新开站点</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="关联优惠券" prop="coupon_ids" v-if="editDialogFormData.type==10">
					<el-button class="add-button" icon="el-icon-plus" type="primary" size="small" @click="addCoupon">增加券</el-button>
					<div v-for="(itm,idx) in editDialogFormData.coupon_ids" v-bind:key="idx">
						<el-select v-model="editDialogFormData.coupon_ids[idx].id" placeholder="请选择" style="width:40%">
							<el-option v-for="item in couponList" :key="item.coupon_id" :label="item.coupon_name"
								:value="item.coupon_id">
							</el-option>
						</el-select>
						<el-input-number type="number" :min="1" :max="100" :step="1" :precision="0" v-model="editDialogFormData.coupon_ids[idx].num" style="width: 108px;" class="input" size="small" placeholder="数量">
						</el-input-number>
						<el-button class="del-button" size="small" type="danger" icon="el-icon-delete" @click="delCoupon(idx)">删除</el-button> 							
					</div>
				</el-form-item>
				<el-form-item label="开始时间" prop="select_time">
					<el-date-picker class="from-item-width" unlink-panels v-model="editDialogFormData.start_time"
						type="datetime" placeholder="开启时间"></el-date-picker>
				</el-form-item>
				<el-form-item label="结束时间" prop="select_time2">
					<el-date-picker class="from-item-width" unlink-panels v-model="editDialogFormData.end_time"
						type="datetime" placeholder="结束时间"></el-date-picker>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" size="small" plain icon="el-icon-check" @click="saveEdit()"
					:disabled="editDisabled" v-if="$hasAccess('market.activity/edit')">保 存 编 辑
				</el-button>
				<el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消
				</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import Tables from '@/components/tables'
	export default {
		name: 'market-activity-list',
		components: {
			Tables
		},
		data() {
			return {
				// 表格信息
				tableName: '充值优惠',
				isLoading: false,
				tableData: [],
				totalPage: 0,
				searchFormData: {},
				couponList: [],
				selecttime: {},
				types: {
					type: {
						10: '正常活动',
						20: '新开站点'
					}
				},
				// 新增
				addDialogShow: false,
				addDialogFormData: {},
				rules: {
					activity_name: [{
						required: true,
						message: '请输入优惠名称',
						trigger: 'change'
					}],
					activity_type: [{
						required: true,
						message: '请选择优惠类型',
						trigger: 'change'
					}],
					activity_discount: [{
						required: true,
						message: '请输入折扣',
						trigger: 'change'
					}],
					max_money: [{
						required: true,
						message: '请输入最多优惠',
						trigger: 'change'
					}],
					day: [{
						required: true,
						message: '请输入有效天数',
						trigger: 'change'
					}]
				},
				// 编辑
				editDialogShow: false,
				editDialogFormData: {},
				editDialogFormOriData: {},
				editNeedData: ['activity_id', 'activity_name', 'type', 'activity_money', 'activity_type', 'gift_money']
			}
		},
		computed: {
			editDisabled() {
				for (let item of this.editNeedData) {
					if (this.editDialogFormData[item] != this.editDialogFormOriData[item]) {
						return false
					}
				}
				return false
			}
		},
		mounted() {
			this.getPlanList();
		},
		activated() {},
		methods: {
			// 表格列表
			async getList(currentPage, pageSize) {
				const params = {
					token: this.$store.state.user.token,
					page: currentPage,
					size: pageSize
				}
				this.isLoading = true
				const searchData = this.searchFormData
				const res = await this.$api.Market.GetActivityList(params, searchData)
				this.tableData = res.data
				this.totalPage = res.total
				this.isLoading = false
			},
			// 获取标签内容
			async getPlanList() {
				const params = {
					token: this.$store.state.user.token,
					page: 1,
					size: 100
				}
				const res = await this.$api.Market.GetCouponList(params, {})
				for (let d of res.data) {
					if (d.max_money != null) {
						d.coupon_name = d.coupon_name + '(' + d.max_money + '元)';
					}
				}
				this.couponList = res.data;
			},
			// 重置搜索
			clearSearch() {
				this.searchFormData = {}
			},
			// 重置form
			resetFormData() {
				this.addDialogFormData = {
					type: 10
				}
				this.editDialogFormData = {}
				this.editDialogFormOriData = {}
				if (this.$refs.addForm) {
					this.$refs.addForm.resetFields()
				}
				if (this.$refs.editForm) {
					this.$refs.editForm.resetFields()
				}
			},
			// 新增
			addOne() {
				this.resetFormData()
				this.addDialogShow = true
			},
			// 编辑
			async editOne(row) {
				this.resetFormData()
				const params = {
					token: this.$store.state.user.token,
					activity_id: row.activity_id
				}
				const res = await this.$api.Market.GetActivityDetail(params)
				if (res) {
					let {
						activity_id,
						activity_name,
						type,
						coupon_id,
						coupon_ids,
						start_time,
						end_time
					} = res;
					if(!coupon_ids && coupon_id){
						coupon_ids = [{id:coupon_id,num:1}];
					}
					if(!coupon_ids) coupon_ids = [];
					this.editDialogFormData = {
						activity_id,
						activity_name,
						type,
						coupon_ids,
						start_time,
						end_time
					}
					this.editDialogFormOriData = {
						activity_id,
						activity_name,
						type,
						coupon_ids,
						start_time,
						end_time
					}
				}
				this.editDialogShow = true
			},
			// 删除
			deleteOne(row) {
				this.$confirm('确定要删除该活动吗', '提示', {
					cancelButtonClass: 'btn-custom-cancel',
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const params = {
						token: this.$store.state.user.token,
						activity_id: row.activity_id
					}
					await this.$api.Market.DeleteActivity(params)
					this.$notify({
						title: '成功',
						message: '删除成功',
						type: 'success'
					})
					this.$refs.xTable.refreshTable()
				})
			},
			// 关闭弹窗
			closeDialog() {
				this.addDialogShow = false
				this.editDialogShow = false
			},
			// 新增保存
			saveAdd() {
				this.$refs.addForm.validate(async valid => {
					if (valid) {
						if (this.addDialogFormData.select_time != undefined) {
							this.addDialogFormData.start_time = parseInt(this.addDialogFormData.select_time
								.getTime() / 1000);
							this.addDialogFormData.end_time = parseInt(this.addDialogFormData.select_time2
								.getTime() / 1000);
						}
						const params = {
							token: this.$store.state.user.token,
							form: JSON.stringify(this.addDialogFormData)
						}
						await this.$api.Market.AddActivity(params)
						this.$notify({
							title: '成功',
							message: '新增成功',
							type: 'success'
						})
						this.closeDialog()
						this.$refs.xTable.refreshTable()
					}
				})
			},
			// 编辑保存
			saveEdit() {
				this.$refs.editForm.validate(async valid => {
					if (valid) {
						this.selecttime.start_time = this.editDialogFormData.start_time.length == undefined ?
							parseInt(this.editDialogFormData.start_time.getTime() / 1000) : this
							.editDialogFormData.start_time;
						this.selecttime.end_time = this.editDialogFormData.end_time.length == undefined ?
							parseInt(this.editDialogFormData.end_time.getTime() / 1000) : this
							.editDialogFormData.end_time;
						this.editDialogFormData.start = this.selecttime.start_time;
						this.editDialogFormData.end = this.selecttime.end_time;
						const params = {
							token: this.$store.state.user.token,
							form: JSON.stringify(this.editDialogFormData)
						}
						await this.$api.Market.EditActivity(params)
						this.$notify({
							title: '成功',
							message: '编辑成功',
							type: 'success'
						})
						this.closeDialog()
						this.$refs.xTable.refreshTable()
					}
				})
			},
			addCoupon() { //增加优惠券
				this.editDialogFormData.coupon_ids.push({id:'',num:1});
			},
			delCoupon(idx) { //增加优惠券
				this.editDialogFormData.coupon_ids.splice(idx, 1);
			}
		}
	}
</script>

<style lang="scss" scoped>
</style>
